<template>
<div class="chatFixed">
    <div class="chatGroupHolder">

        <PageHeader class="chatPageHeader" title="Chats"/>

        <div class="chat">

            <div class="chatList">
                <AllChats @selectChat="selectChat"/>
            </div>

            <div class="selectedChat">
                <SelectedChat @close="() => { selectedChat = null }" :selectedChat="selectedChat" v-if="selectedChat"/>
            </div>

        </div>
            
    </div>
</div>
</template>
<script>
import { mapGetters } from 'vuex'

import AllChats from '../components/chats/AllChats.vue'
import SelectedChat from '../components/chats/SelectedChat.vue'

    export default {
        components: {
            AllChats,
            SelectedChat
        },
        data() {
            return {
                selectedChat: null
            }
        },
        computed: {
            ...mapGetters(['chat'])
        },
        methods: {
            hideChat() {
                this.$store.commit('chat', false);
            },
            selectChat(item) {
                this.selectedChat = item;
            }
        },
        watch: {
            chat() {
                if(this.chat) {
                    document.body.classList.add('noScroll');
                } else {
                    document.body.classList.remove('noScroll');
                }
            }
        }
    }
</script>

<style lang="scss">
.page {
    padding-bottom: 0 !important;
}
</style>

<style lang="scss" scoped>

.chatFixed {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.chatGroupHolder {
    height: 100%;
    display: grid;
    grid-template-rows: auto minmax(0, 1fr);
    padding: 0 0 0 100px;
    margin-right: 20px;
    border-right: 1px solid $borderColor;
}

.chatPageHeader {
    padding: 0 20px;
    margin: 0;
    border-bottom: 1px solid $borderColor;
}

.chat {
    z-index: 9999;
    transition: ease 0.5s;
    height: 100%;
    display: grid;
    grid-template-columns: 30% 70%;
}

.chatList {
    height: 100%;
    overflow-y: auto;
    border-right: 1px solid $borderColor;
}

.selectedChat {
    height: 100%;
}

.chatBg {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.3);
    z-index: 9999;
    pointer-events: none;
    opacity: 0;
    transition: ease 0.5s;
}

.chatGroup {
    height: 100%;
}

</style>

<style lang="scss" scoped>

</style>